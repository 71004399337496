<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->

        <div class="">
          <img class="fp_logo rounded" :src="logo" alt="" style="object-fit: contain"/>
        </div>
        <!-- Switz -->
      </b-link>
      <!-- /Brand logo-->

      <b-col lg="6" class="d-none d-lg-flex p-0">
        <div class="w-100 d-lg-flex">
          <b-img class="registerImage" fluid :src="imgUrl" alt="Register V2" style="object-fit: cover" />
        </div>
      </b-col>

      <!-- Left Text-->

      <b-col lg="6" class="d-flex align-items-center auth-bg px-0 p-lg-2">
        <!-- <div class="fl_name"> -->

        <b-col sm="8" md="6" lg="12" class="px-xl-2 pt-5 pt-lg-0 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Connect With Us and Unlock New Possibilities
          </b-card-title>

          <b-card-text class="mb-2">
            Ready to Achieve Global Success? Sign In to Start Your Journey
          </b-card-text>

          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">
              <!-- First Name/Last Name -->
              <div class="row">
                <!-- FirstName -->
                <div class="col-12 col-md-6">
                  <b-form-group label="First name" label-for="register-firstname">
                    <validation-provider #default="{ errors }" name="Firstname" rules="required">
                      <b-form-input id="register-firstname" v-model="form.firstname" name="register-firstname"
                        :state="errors.length > 0 ? false : null" placeholder="" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <!-- LastName -->
                <div class="col-12 col-md-6">
                  <b-form-group label="Last name" label-for="register-lastname">
                    <validation-provider #default="{ errors }" name="Lastname" rules="required">
                      <b-form-input id="register-lastname" v-model="form.lastname" name="register-lastname"
                        :state="errors.length > 0 ? false : null" placeholder="" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <!-- Phone number -->
                <div class="col-12 col-md-6">
                  <b-form-group label="Phone number" label-for="register-mobile">
                    <validation-provider #default="{ errors }" name="Phone number" rules="required">
                      <!-- <b-form-input
                        id="register-mobile"
                        v-model="form.mobile"
                        type="number"
                        name="register-mobile"
                        :state="errors.length > 0 ? false : null"
                        placeholder="+91XXXXXXXXXX"
                      /> -->
                      <vue-tel-input id="register-mobile" v-model="form.mobile" name="register-mobile"
                        :state="errors.length > 0 ? false : null" class="py-25" v-on:country-changed="countryChanged"
                        :dropdownOptions="{
                          showSearchBox: true,
                          showFlags: true,
                          showDialCodeInSelection: true,
                        }" :inputOptions="{
                          placeholder: 'Enter phone number',
                          required: true,
                          showDialCode: false,
                        }" defaultCountry="IN"></vue-tel-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <!-- email -->
                <div class="col-12 col-md-6">
                  <b-form-group label="Email" label-for="register-email">
                    <validation-provider #default="{ errors }" name="Email" rules="required|email">
                      <b-form-input id="register-email" v-model="form.email" name="register-email"
                        :state="errors.length > 0 ? false : null" placeholder="" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <!-- password -->
                <div class="col-12 col-md-6">
                  <b-form-group label-for="register-password" label="Password">
                    <validation-provider #default="{ errors }" name="Password" rules="required">
                      <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                        <b-form-input id="register-password" v-model="form.password"
                          class="form-control-merge rp_padding" :type="passwordFieldType"
                          :state="errors.length > 0 ? false : null" name="register-password"
                          placeholder="············" />
                        <b-input-group-append is-text>
                          <feather-icon :icon="passwordToggleIcon" class="cursor-pointer"
                            @click="togglePasswordVisibility" />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <!-- confirm password -->
                <div class="col-12 col-md-6">
                  <b-form-group label-for="register-password2" label="Confirm Password">
                    <validation-provider #default="{ errors }" name="Confirm Password" rules="required">
                      <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                        <b-form-input id="register-password2" v-model="form.confirm_password"
                          class="form-control-merge rp_padding" :type="passwordFieldTypeRepeat"
                          :state="errors.length > 0 ? false : null" name="register-password2"
                          placeholder="············" />
                        <b-input-group-append is-text>
                          <feather-icon :icon="passwordToggleIconRepeat" class="cursor-pointer"
                            @click="togglePasswordVisibilityRepeat" />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
              <!-- <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="form.status"
                  name="checkbox-1"
                >
                  I agree to
                  <b-link>privacy policy & terms</b-link>
                </b-form-checkbox>
              </b-form-group> -->
              <div class="">
                <b-button variant="primary" block type="submit" @click.prevent="StudentRegister">
                  Sign up
                </b-button>
              </div>
            </b-form>
          </validation-observer>

          <p v-if="!is_agent_link" class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{ name: 'login' }">
              <span>&nbsp;Sign in instead</span>
            </b-link>
          </p>
          <div v-if="is_agent_link" class="mt-4 text-center">
            <h4 class="font-weight-bold">{{ agent_info.agent_name }}</h4>
            <b-img :src="FILESURL + agent_info.brand_image" width="200px" />
          </div>
          <div v-if="is_sc_link" class="mt-4 text-center">
            <h4 class="font-weight-bold">{{ sc_info.sc_name }}</h4>
            <b-img :src="FILESURL + sc_info.sc_image" width="200px" />
          </div>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SwitzLogo from "@/theme-variables/assets/Logo.png";
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("lazy");

import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import { required, email } from "@validations";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AuthServices from "@/apiServices/AuthServices";
import { TokenService } from "@/apiServices/storageService";
import HomeServices from "@/apiServices/HomeServices";
import { FILESURL } from "@/config";
import helpers from "@/libs/helpers";

export default {
  components: {
    // VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      form: {
        status: "",
        firstname: "",
        lastname: "",
        mobile: "",
        // user_country_code: "",
        // user_mobile_code: "",
        email: "",
        password: "",
        confirm_password: "",
        refer_user_id: null,
      },
      passwordFieldType: "password",
      passwordFieldTypeRepeat: "password",
      sideImg: require("@/assets/images/pages/login/new-bg.jpg"),
      is_agent_link: false,
      is_sc_link: false,
      agent_info: {
        agent_user_id: null,
        agent_name: null,
        brand_image: null,
      },
      sc_info: {
        sc_user_id: null,
        sc_name: null,
        sc_image: null,
      },
      // validation
      required,
      email,
      logo: SwitzLogo,
      FILESURL,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIconRepeat() {
      return this.passwordFieldTypeRepeat === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login/new-bg.jpg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    countryChanged(input) {
      if (this.form.mobile.split(" ").length > 1) {
        this.form.mobile = "+" + input.dialCode + " " + this.form.mobile;
      } else {
        this.form.mobile = "+" + input.dialCode;
      }
    },
    async StudentRegister() {
      this.$refs.registerForm.validate().then(async (success) => {
        if (success) {
          if (this.form.password !== this.form.confirm_password) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Password does not match",
                icon: "EditIcon",
                variant: "failure",
              },
            });
            return;
          }

          try {
            let response = null;
            let payload = this.form;
            const location = await helpers.getLocation(navigator);

            if (location) {
              payload = {
                ...payload,
                latitude: location.coords.latitude,
                longitude: location.coords.longitude,
                accuracy: location.coords.accuracy,
              };
            }

            if (this.is_agent_link && this.agent_info.agent_user_id) {
              response = await AuthServices.studentRegisterWithAgentLink({
                agent_user_id: this.agent_info.agent_user_id,
                ...payload,
              });
            } else if (this.is_sc_link && this.sc_info.sc_user_id) {
              response = await AuthServices.studentRegisterWithScLink({
                sc_user_id: this.sc_info.sc_user_id,
                ...payload,
              });
            } else {
              response = await AuthServices.StudentRegister(payload);
            }

            if (response && response.data.status) {
              TokenService.saveToken(response.data.data.token);

              this.$store.commit(
                "user/SET_USER_DETAILS",
                response.data.data.user
              );
              this.$store.commit("user/SET_USER_AUTHENTICATED", true);

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || "Signed Up",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.$router.push({ name: "home" });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || "Validation Failed",
                  icon: "EditIcon",
                  variant: "failure",
                },
              });
            }
          } catch (error) {
            console.log("StudentRegister ", error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Server Error",
                icon: "EditIcon",
                variant: "failure",
              },
            });
          }
        }
      });
    },
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    togglePasswordVisibilityRepeat() {
      this.passwordFieldTypeRepeat =
        this.passwordFieldTypeRepeat === "password" ? "text" : "password";
    },
    async getAgentDetails(agent_user_id) {
      try {
        const response = await HomeServices.getAgentInfo({ agent_user_id });
        if (response.data.status) {
          this.is_agent_link = true;
          this.agent_info = {
            ...this.agent_info,
            ...response.data.data,
          };
        }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    },
    async getSchoolCounselorDetails(sc_user_id) {
      try {
        const response = await HomeServices.getSchoolCounselorInfo({
          sc_user_id,
        });
        console.log(response.data);
        if (response.data.status) {
          const data = response.data.data;
          this.is_sc_link = true;
          this.sc_info = {
            sc_user_id: data.sc_user_id,
            sc_name: data.sc_name,
            sc_image: data.sc_image,
          };
        }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    },
  },
  beforeMount() {
    const url_search = new URLSearchParams(window.location.search);
    const agent_user_id = url_search.get("associate_user_id");
    const sc_user_id = url_search.get("sc_user_id");
    const refer_user_id = url_search.get("refer_user_id");
    if (agent_user_id && !isNaN(agent_user_id)) {
      this.getAgentDetails(agent_user_id);
    } else if (sc_user_id && !isNaN(sc_user_id)) {
      this.getSchoolCounselorDetails(sc_user_id);
    } else if (refer_user_id) {
      this.form.refer_user_id = refer_user_id;
    }
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
